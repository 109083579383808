import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Link from '../link/Link';

const styles = () => ({
  media: {
    'max-width': '100%',
    display: 'flex',
    boxShadow: '0px 4px 10px 0px rgba(0,0,0,0.3)'
  }
});

const Hero = ({ classes, title, subtitle, button, image }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Grid
      container
      item
      direction="row-reverse"
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={12} md={5}>
        <img src={image.url} alt={image.alt} className={classes.media} />
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h3" component="h1" gutterBottom>
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h2"
            gutterBottom
          >
            {subtitle}
          </Typography>
        )}
        {button && (
          <Link to={button.to}>
            <Button variant="contained" color="secondary">
              {button.title}
            </Button>
          </Link>
        )}
      </Grid>
    </Grid>
  );
};

Hero.propTypes = {
  classes: PropTypes.shape({ media: PropTypes.string }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    copyright: PropTypes.string
  }).isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  })
};
Hero.defaultProps = {
  subtitle: null,
  button: null
};

export default withStyles(styles)(Hero);

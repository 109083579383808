import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import Link from '../link/Link';

const styles = theme => ({
  container: {
    padding: theme.spacing(8, 0)
  },
  textColor: {
    color: '#fff'
  },
  button: {
    marginTop: theme.spacing(2)
  }
});

const Cta = ({ classes, title, subtitle, button }) => (
  <Grid
    container
    item
    direction="column"
    justify="center"
    alignItems="center"
    className={classes.container}
  >
    <Grid item md={12}>
      <Typography
        variant="h5"
        component="h4"
        align="center"
        className={classes.textColor}
        gutterBottom
      >
        {title}
      </Typography>
    </Grid>
    <Grid item md={12}>
      <Typography
        variant="h6"
        component="h5"
        align="center"
        className={classes.textColor}
        gutterBottom
      >
        {subtitle}
      </Typography>
    </Grid>
    <Grid item md={12}>
      <Link to={button.to}>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
        >
          {button.title}
        </Button>
      </Link>
    </Grid>
  </Grid>
);

Cta.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    button: PropTypes.string.isRequired
  }).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  button: PropTypes.shape({
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired
  }).isRequired
};

export default withStyles(styles)(Cta);

import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';

import Link from '../link/Link';

const FileCard = ({ title, image, document }) => (
  <Grid item md={3}>
    <Card>
      <Link to={document.url}>
        <CardActionArea>
          <CardMedia
            component="img"
            title={image.alt}
            alt={image.alt}
            image={image.url}
          />
        </CardActionArea>
        <CardContent>
          <Typography variant="h5" component="h3" align="center">
            {title}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  </Grid>
);

FileCard.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    copyright: PropTypes.string
  }).isRequired,
  document: PropTypes.shape({
    url: PropTypes.string.isRequired
  }).isRequired
};

export default FileCard;
